import { classed } from '@tw-classed/react'
import { cva, type VariantProps } from 'class-variance-authority'
import { forwardRef, ReactNode } from 'react'

import { Link } from '../Link'

import { cn } from '@/lib/utils'

const BaseLabel = classed('span')

const labelVariants = cva(
  'inline-flex font-medium antialiased leading-[1] text-label-small-m truncate whitespace-nowrap px-[10px] py-[6px] rounded-24px ring-1 ring-transparent transition duration-300',
  {
    variants: {
      variant: {
        white: 'bg-white text-blue',
        blue: 'bg-baby-blue text-trueBlue',
      },
    },
    defaultVariants: {
      variant: 'blue',
    },
  },
)

export type LabelProps = {
  children: ReactNode
  disabled?: boolean
  className?: string
} & VariantProps<typeof labelVariants> &
  (
    | { href: string; onClick?: never }
    | { href?: never; onClick: () => void }
    | { href?: never; onClick?: never }
  )

const Label = forwardRef<
  HTMLAnchorElement & HTMLButtonElement & HTMLSpanElement,
  LabelProps
>(({ className, href, variant, onClick, children, ...props }, ref) => {
  let interactionClasses = ``

  switch (variant) {
    case 'blue':
      interactionClasses = /** @tw */ 'hover:bg-white hover:ring-blue'
      break
    case 'white':
      interactionClasses = /** @tw */ 'hover:bg-baby-blue hover:ring-blue'
      break
    default:
      break
  }

  if (href) {
    return (
      <Link
        ref={ref}
        href={href}
        className={cn(
          labelVariants({ variant }),
          className,
          interactionClasses,
        )}
        {...props}
      >
        {children}
      </Link>
    )
  }

  return (
    <BaseLabel
      ref={ref}
      as={onClick ? 'button' : 'span'}
      {...(href ? { href } : {})}
      {...(onClick ? { onClick } : {})}
      className={cn(
        labelVariants({ variant }),
        className,
        onClick && interactionClasses,
      )}
      {...props}
    >
      {children}
    </BaseLabel>
  )
})

export { Label, labelVariants }

export default Label
