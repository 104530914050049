import { ArrowRight } from 'lucide-react'
import { forwardRef, HTMLAttributes } from 'react'

import { SearchResult } from './types'

import { Text } from '@/components/Text'
import { Skeleton } from '@/components/ui/skeleton'
import { cn, containsLongWord, getHtmlText } from '@/lib/utils'

type CardResultProps = {
  item?: SearchResult
  highlighted?: boolean
  selected?: boolean
  searchString?: string
}

const highlightWords = (searchWords: string, text: string): string => {
  const words = searchWords.split(' ')
  words.sort((a, b) => b.length - a.length)
  const escapeRegExp = (word: string) =>
    word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

  words.forEach((word) => {
    const pattern = new RegExp(`(${escapeRegExp(word)})`, 'gi')
    text = text.replace(pattern, '<span style="color: #324CDB">$1</span>')
  })

  return text
}

export const CardResult = forwardRef<HTMLLIElement, CardResultProps>(
  ({ item, highlighted, selected, searchString = '', ...props }, ref) => {
    const highlightedMainText = item?.labelMain
      ? highlightWords(searchString, item.labelMain)
      : ''
    const highlightedSubText = item?.labelSub
      ? highlightWords(searchString, item.labelSub)
      : ''

    const strippedMainText = getHtmlText(highlightedMainText)

    const highlightedClassName: HTMLAttributes<HTMLDivElement>['className'] =
      containsLongWord(strippedMainText) ? 'truncate' : 'line-clamp-3'

    return (
      <li
        ref={ref}
        className={cn(
          'py-6 px-8 rounded-2xl flex flex-col border border-baby-blue ring-1 ring-transparent cursor-pointer',
          highlighted && 'ring-1 ring-blue',
          selected && 'ring-1 ring-blue',
        )}
        {...props}
      >
        <Text as="span" variant="label-medium" className="font-medium mb-4">
          {highlightedMainText ? (
            <div
              className={highlightedClassName}
              dangerouslySetInnerHTML={{ __html: highlightedMainText }}
            />
          ) : null}
          {!item && (
            <>
              <Skeleton className="h-[22px] w-[250px] rounded-bl-none" />
              <Skeleton className="h-[22px] w-[180px] rounded-t-none" />
            </>
          )}
        </Text>
        <Text
          as="div"
          variant="label-small"
          className="text-gray-3 flex items-center gap-4"
        >
          <div className="flex-1 truncate">
            {highlightedSubText ? (
              <div dangerouslySetInnerHTML={{ __html: highlightedSubText }} />
            ) : null}
            {!item && <Skeleton className="h-4 w-[250px]" />}
          </div>
          <div className="ml-auto flex-shrink-0 h-6 w-6 bg-gray-1 rounded-full flex items-center justify-center">
            {item && (
              <ArrowRight
                width={undefined}
                height={undefined}
                className="w-3.5 text-dark"
              />
            )}
            {!item && <Skeleton className="h-3.5 w-3.5 rounded-full" />}
          </div>
        </Text>
      </li>
    )
  },
)

export default CardResult
