import { Slug } from 'sanity'

export type SlugType =
  | Slug
  | {
      current?: string | null
      _type: 'slug'
    }
  | null

export type SlugsType = SlugType | SlugType[] | null

export function linkResolver(
  slug?: SlugsType,
  _type?: string,
  locale: string = 'is',
  parentSlug?: string,
) {
  if (!_type) {
    return '/'
  }

  const slugString = Array.isArray(slug)
    ? slug.reduce((acc, cur) => {
        if (cur?.current) {
          acc = acc ? acc + '/' + cur.current : cur.current
        }

        return acc
      }, '')
    : slug?.current

  switch (_type) {
    case 'homepage':
      return `/${locale}`
    case 'iframeForm':
      return `/umsoknir/${slugString ?? ''}`
    case 'page':
      return `/${locale}/${slugString ?? ''}`
    case 'news':
      return `/frettir/${slugString ?? ''}`
    case 'search':
      return `/leit/${slugString ?? ''}`
    case 'contentPage': {
      const parentSlugSegment = parentSlug ? `/${parentSlug}` : ''
      return `/${locale}/${locale === 'is' ? 'upplysingar' : 'information'}${parentSlugSegment}/${slugString ?? ''}`
    }
    case 'contentPageRoot':
      return `/${locale}/${locale === 'is' ? 'upplysingar' : 'information'}/${slugString ?? ''}`
    default:
      return `/${locale}`
  }
}
