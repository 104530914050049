'use client'

import { useCombobox } from 'downshift'
import debounce from 'lodash/debounce'
import { Search } from 'lucide-react'
import { useRouter, useSearchParams } from 'next/navigation'
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

import CardResult from './CardResult'
import { SearchResult } from './types'
import { Label } from '../Label'
import { Text } from '../Text'

import { cn } from '@/lib/utils'
import { linkResolver } from '@/sanity/lib/linkResolver'
import { SearchQueryResult } from '@/types/general'
import { SearchPageQueryResult } from '@/types/sanity.types'

export interface SearchInputProps {
  label: string
  results: SearchQueryResult | null
  placeholder?: string
  labelMostSearched?: string
  className?: string
  defaultInputValue?: string
  quickLinks?: SearchPageQueryResult['quickLinks'] | null
  language?: string
}

export const SearchInput = forwardRef(
  (
    {
      label,
      results = null,
      placeholder,
      labelMostSearched = 'Mest leitað að',
      className,
      quickLinks,
      language = 'is',
    }: SearchInputProps,
    ref,
  ) => {
    const router = useRouter()
    const searchParams = useSearchParams()
    const localRef = useRef<HTMLInputElement | null>(null)
    const [isLoading, setIsLoading] = useState(false)
    const [inputValue, setInputValue] = useState<string>(
      searchParams.get('q')?.toLocaleLowerCase()?.trim() ?? '',
    )
    const [searchResults, setSearchResults] = useState<SearchResult[]>([])
    const hasSearchParams = !!searchParams.get('q')

    useEffect(() => {
      if (results) {
        const items = (results?.faqs ?? []).map((item) => ({
          id: item._id,
          labelMain: item.question ?? '',
          labelSub: '',
          href: '',
        }))

        setSearchResults(items)
      }
    }, [results])

    useImperativeHandle(ref, () => localRef.current)

    useEffect(() => {
      if (localRef?.current) {
        localRef.current?.focus()
      }
    }, [])

    const refetch = useCallback(
      async (inputValue: string) => {
        setIsLoading(true)

        const searchString = (inputValue ?? '')
          .toLocaleLowerCase()
          ?.replace(/\s+/g, ' ')
          .trim()

        const data = await fetch('/api/search', {
          method: 'POST',
          body: JSON.stringify({
            searchString,
            language,
          }),
        })

        const results: SearchQueryResult = await data.json()

        // show only faqs
        let newSearchResults: SearchResult[] = (results?.faqs ?? []).map(
          (item) => ({
            id: item._id,
            labelMain: item.question ?? '',
            labelSub: '',
            // just change search when selected for now
            href: '',
          }),
        )

        // but if no faqs are found, show pages
        if (!newSearchResults?.length) {
          newSearchResults = (results?.pages ?? []).map((item) => ({
            id: item._id,
            labelMain: item.title ?? '',
            labelSub: item.title?.substring(0, 100) ?? '',
            href: linkResolver(item.slug, item._type),
          }))
        }

        setSearchResults(newSearchResults)
        setIsLoading(false)
      },
      [language],
    )

    const debouncedFilter = useMemo(
      () =>
        debounce((input) => {
          refetch(input)
        }, 300),
      [refetch],
    )

    useEffect(() => {
      debouncedFilter(inputValue)

      return () => {
        debouncedFilter.cancel()
      }
    }, [inputValue, debouncedFilter])

    const onSubmitSearchString = (str: string) => {
      router.push(linkResolver(null, 'search') + `?q=${str}`, { scroll: false })
    }

    const {
      isOpen,
      getToggleButtonProps,
      getLabelProps,
      getMenuProps,
      getInputProps,
      highlightedIndex,
      getItemProps,
      selectedItem,
    } = useCombobox({
      onStateChange(changes) {
        switch (changes.type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
            // Handle user pressing enter without selecting an item
            !changes.selectedItem && onSubmitSearchString(inputValue)
            break
          default:
            break
        }
      },
      onSelectedItemChange({ selectedItem }) {
        if (selectedItem?.href) {
          router.push(selectedItem.href, { scroll: false })
        } else {
          onSubmitSearchString(inputValue)
        }
      },
      items: searchResults,
      inputValue,
      itemToString: () => {
        return inputValue
      },
    })

    let isHidden = true

    if (isOpen) {
      if (isLoading) {
        isHidden = false
      } else {
        if (searchResults.length) {
          isHidden = false
        } else {
          isHidden = true
        }
      }
    }

    const searchUrl = linkResolver(null, 'search')

    return (
      <>
        <div className={cn('relative max-w-96 mx-auto mb-6', className)}>
          <div className="px-3">
            <label className="sr-only" {...getLabelProps()}>
              {label}
            </label>

            <div className="flex h-[55px] relative">
              <input
                placeholder={placeholder}
                className="w-full placeholder:text-gray-3 text-dark rounded-48px ease-in-out bg-white px-3 disabled:cursor-not-allowed disabled:opacity-50 shadow-base focus:shadow-base2 pl-16 pr-6 outline-none focus-visible:ring-1 focus-visible:ring-blue focus:ring-1 focus:ring-blue transition duration-150 ring-1 ring-transparent"
                {...getInputProps({
                  ref: localRef,
                  onChange: (event) => {
                    setIsLoading(true)
                    const value = (event.target as { value: string })?.value
                    const str = value?.replace(/\s+/g, ' ') ?? ''
                    setInputValue(str)
                  },
                })}
              />
              <button
                aria-label="toggle menu"
                className={cn(
                  'rounded-full bg-gray-1 items-center flex justify-center absolute top-1/2 bottom-1/2 left-5 h-6 w-6 -translate-y-1/2 transition duration-150 ease-in-out',
                  {
                    'text-white bg-blue': isOpen,
                  },
                )}
                type="button"
                {...getToggleButtonProps()}
              >
                <Search
                  width={undefined}
                  height={undefined}
                  className="w-3.5"
                />
              </button>
            </div>
          </div>
          <ul
            className={cn(
              'absolute w-full mt-2.5 rounded-2xl bg-white px-5 py-5 z-10 space-y-2',
              {
                hidden: isHidden,
              },
            )}
            {...getMenuProps()}
          >
            {isOpen &&
              (isLoading
                ? Array.from({ length: 2 }).map((_, index) => (
                    <CardResult key={`skeleton-${index}`} />
                  ))
                : searchResults
                    .slice(0, 4)
                    .map((item, index) => (
                      <CardResult
                        key={`${item.id}-${index}`}
                        item={item}
                        highlighted={highlightedIndex === index}
                        selected={selectedItem === item}
                        searchString={inputValue}
                        {...getItemProps({ item, index })}
                      />
                    )))}
          </ul>
        </div>
        {quickLinks && !hasSearchParams && (
          <div className="px-4 flex flex-col items-center justify-center">
            <div className="mt-12 mb-8 text-gray-3">
              {labelMostSearched && (
                <Text variant="label-small">{labelMostSearched}</Text>
              )}
            </div>
            <div className="overflow-x-scroll max-w-[100vw] hide-scrollbar py-1 px-4 pr-4">
              <div className="flex gap-4 md:gap-8">
                {(quickLinks?.quickSearch ?? []).map((link, index) => {
                  return (
                    <div key={index}>
                      <Label
                        variant="blue"
                        onClick={() => {
                          setInputValue(
                            link?.string?.toLocaleLowerCase()?.trim() ?? '',
                          )
                          router.push(
                            `${searchUrl}?q=${link?.string?.toLocaleLowerCase()?.trim() ?? ''}`,
                            { scroll: false },
                          )
                        }}
                      >
                        {link?.string}
                      </Label>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        )}
      </>
    )
  },
)

export default SearchInput
